import React from 'react'
import { string, bool, func } from 'prop-types'
import { Form, Formik } from 'formik'
import {
  TextInputField,
  PasswordFieldWithRequirements
} from '@toasttab/buffet-pui-forms'
import { MerryGoRound, SIZE } from '@toasttab/buffet-pui-loading-indicators'
import { useHeap } from '@toasttab/use-heap'
import { Footer } from '../Footer'
import { ActivationValidationSchema, EnterpriseIdSchema } from './validation'
import { ReadonlyText } from '@toasttab/buffet-pui-readonly'
import { FooterActions } from '../FooterActions/FooterActions'

export const ActivationForm = ({
  passcode,
  email,
  hasPassword,
  identities,
  onSubmit,
  onCancel,
  fieldsDisabled,
  showPasscodeField,
  location,
  isMarriottPilotRestaurant,
  isEnterpriseCustomer
}) => {
  const { redactAttributes, redactTextContent } = useHeap()
  const SSO_MARRIOTT = 'sso_marriott'
  const getEnterpriseIdData = (identities, enterpriseTypeId = SSO_MARRIOTT) => {
    const ssoEnterprise = identities?.find(
      (idProvider) => idProvider.identityType === enterpriseTypeId
    )
    return {
      enterpriseId: ssoEnterprise?.value,
      isEnterpriseIdVerified: ssoEnterprise?.verified
    }
  }

  const { enterpriseId, isEnterpriseIdVerified } =
    getEnterpriseIdData(identities)

  const initialValues = {
    password: '',
    passcode,
    hasPassword,
    email,
    ...(isMarriottPilotRestaurant && { enterpriseId })
  }

  const shouldDisplayEidFieldReadOnly =
    isMarriottPilotRestaurant && isEnterpriseIdVerified && enterpriseId

  const shouldDisplayEidFieldEditable =
    isMarriottPilotRestaurant && !isEnterpriseIdVerified

  const shouldDisplayPasswordField =
    !isMarriottPilotRestaurant && !isEnterpriseCustomer && !hasPassword

  const validationSchema = isMarriottPilotRestaurant
    ? ActivationValidationSchema.concat(EnterpriseIdSchema)
    : ActivationValidationSchema

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        const isLoading = formik.isSubmitting

        //TODO: This be tidied up into a more general form
        const canSubmit =
          !isLoading &&
          formik.isValid &&
          (formik.dirty ||
            hasPassword ||
            (isEnterpriseCustomer && !!isMarriottPilotRestaurant) ||
            (isMarriottPilotRestaurant && !!formik.values.enterpriseId))

        return (
          <Form className='grid grid-cols-1 gap-6'>
            <div className='grid grid-flow-row grid-rows-3-min gap-6 break-all lg:grid-cols-2 lg:grid-rows-2-min'>
              <ReadonlyText
                className='md:pt-2'
                label='Email address'
                id='email-address'
                value={email}
                {...redactTextContent()}
              />
              {location && (
                <ReadonlyText
                  className='md:pt-2'
                  label='POS Location'
                  id='pos-location'
                  value={location}
                  {...redactTextContent()}
                />
              )}
              {shouldDisplayPasswordField && (
                <PasswordFieldWithRequirements
                  name='password'
                  label='Create password'
                  testId='password-field'
                  disabled={fieldsDisabled}
                  {...redactAttributes(['value'])}
                />
              )}
              {showPasscodeField && (
                <TextInputField
                  testId='passcode-field'
                  maxLength={8}
                  name='passcode'
                  placeholder='POS access code'
                  label='Create POS access code'
                  helperText={`Must be ${
                    /@toasttab.com$/.test(email) ? 6 : 3
                  } to 8 digits.`}
                  inputMode='numeric'
                  disabled={fieldsDisabled}
                  {...redactAttributes(['value'])}
                />
              )}
              {shouldDisplayEidFieldReadOnly && (
                <ReadonlyText
                  className='md:pt-2'
                  label='Enterprise ID (EID)'
                  id='eid-readonly'
                  value={enterpriseId}
                  {...redactTextContent()}
                />
              )}
              {shouldDisplayEidFieldEditable && (
                <TextInputField
                  testId='enterpriseId'
                  name='enterpriseId'
                  label='Enterprise ID (EID)'
                  {...redactAttributes(['value'])}
                  helperText={
                    'This ID was given to you by your employer. If you are unsure what your EID is, please contact your manager.'
                  }
                />
              )}
            </div>

            <Footer />

            {isLoading && (
              <div className='flex flex-row justify-center'>
                <MerryGoRound
                  size={SIZE.md}
                  testId='submit-form-loading-indicator'
                />
              </div>
            )}
            <FooterActions
              onCancel={onCancel}
              disabled={fieldsDisabled}
              canSubmit={canSubmit}
            />
          </Form>
        )
      }}
    </Formik>
  )
}

ActivationForm.propTypes = {
  showPasscodeField: bool.isRequired,
  passcode: string,
  email: string.isRequired,
  onSubmit: func.isRequired,
  fieldsDisabled: bool.isRequired,
  location: string,
  onCancel: func.isRequired,
  hasPassword: bool
}
