import * as React from 'react'
import ReactDOM from 'react-dom'
import { banquetSingleSpaReact } from 'banquet-runtime-modules'
import { singleSpaCssLifecycles } from '@toasttab/banquet-single-spa-css'
import { AppBootstrap } from './components/App'
import './index.css'

const reactLifecycles = banquetSingleSpaReact({
  React,
  ReactDOM,
  cssScope: 'data-ua',
  singleSpaCssLifecycles,
  rootComponent: AppBootstrap,
  sentry: {
    publicKey: '23d9227254b54b59b655bdc764b1b1c4@o37442',
    projectId: '5514218',
    releaseVersion: process.env.PKG_VERSION
  }
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
