import React from 'react'
import { shape, string, bool, func, oneOfType, arrayOf, node } from 'prop-types'
import { useQuery, useMutation } from 'react-query'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { Alert } from '@toasttab/buffet-pui-alerts'
import {
  ErrorPage500,
  ErrorHeading,
  ErrorSubheading
} from '@toasttab/buffet-pui-error-pages'
import {
  ContentHeader,
  PageAside,
  Page,
  PageContainer,
  PageBody,
  PageMain
} from '@toasttab/buffet-pui-wizard-templates'
import { useHeap } from '@toasttab/use-heap'
import {
  ReactQueryKeys,
  getActivationContext,
  activateAccount as activateAccountApi
} from '../../utils/apis'
import { printError } from '../../utils/printError'
import { getLoginUrl } from '../../utils/login'
import { MutationResponseAlert } from '../MutationResponseAlert'
import { useReturnUrl } from '../../hooks/useReturnUrl'
import { useFlags } from '../../hooks/useFlags'
import { Details } from '../Details'
import { PageHeader } from '../PageHeader'
import { ActivationForm } from '../ActivationForm'

import { getSubtitleText } from '../../utils/headerText'

const BasePage = ({ children, showPasscodeDetails, passcodeMinLength }) => (
  <Page>
    <PageHeader />
    <PageContainer>
      <PageBody>
        <PageMain>{children}</PageMain>
        <PageAside>
          <Details
            showPasscode={showPasscodeDetails}
            passcodeMinLength={passcodeMinLength}
          />
        </PageAside>
      </PageBody>
    </PageContainer>
  </Page>
)

const is500 = (statusCode = 0) => statusCode >= 500 && statusCode <= 599

const StatePage = ({ children }) => (
  <BasePage>
    <div className='flex flex-row justify-center'>{children}</div>
  </BasePage>
)

export const ConnectedApp = () => {
  const { track, identify, addUserProperties } = useHeap()
  const {
    data,
    isLoading: isQueryLoading,
    isError: isQueryError,
    error: queryError
  } = useQuery(ReactQueryKeys.ACTIVATION_CONTEXT, getActivationContext, {
    onSuccess: (responseData) => {
      /**
       * Default to `TOAST` if absent to not disrupt the flow until Toastweb
       * has been changed.
       */
      const {
        userAccount,
        // `restaurant` will be null during Payroll/Xtrachef users activation
        restaurant = null,
        application = 'TOAST'
      } = responseData
      identify(userAccount?.guid)
      addUserProperties({
        isToastUser: /@toasttab.com/.test(userAccount?.email),
        hasPassword: userAccount?.hasPassword,
        restaurantGuid: restaurant?.guid,
        isToastNowOnly: restaurant?.isToastNowOnly,
        application
      })
    }
  })

  const { redirectUrl, decodedReturnUrl } = useReturnUrl(
    data?.userAccount?.email
  )

  const [activateAccount, mutationResponse] = useMutation(activateAccountApi, {
    onSuccess: (responseData) => {
      track('Successfully activated account', {
        returnUrl: decodedReturnUrl
      })
      const loginUrl = responseData || getLoginUrl(data?.application)
      window.location.assign(
        loginUrl + (loginUrl.includes('?') ? '&' : '?') + redirectUrl
      )
    }
  })

  if (isQueryLoading) {
    return (
      <StatePage>
        <MerryGoRound size='xxl' testId='initial-query-loading-indicator' />
      </StatePage>
    )
  }
  if (isQueryError && is500(queryError?.response?.status)) {
    return (
      <StatePage>
        <ErrorPage500 errorText={queryError?.response?.status}>
          <ErrorHeading>Some services are currently unavailable</ErrorHeading>
          <ErrorSubheading>
            We're working hard to resolve this issue. This outage will prevent
            you from activating your account.
          </ErrorSubheading>
        </ErrorPage500>
      </StatePage>
    )
  }

  if (isQueryError) {
    return (
      <StatePage>
        <Alert className='mb-6' variant='error' showIcon>
          {printError(queryError)}
        </Alert>
      </StatePage>
    )
  }

  return (
    <App
      {...data}
      activateAccount={({ passcode, ...values }) =>
        activateAccount({
          // password
          ...values,
          passcode: passcode || data?.userAccount.passcode,
          userGuid: data?.userAccount.guid,
          restaurantGuid: data?.restaurant?.guid
        })
      }
    >
      <MutationResponseAlert {...mutationResponse} />
    </App>
  )
}

export const App = ({
  userAccount,
  activateAccount,
  isActivationSession,
  children: alert,
  application = 'TOAST',
  restaurant = null
}) => {
  // If the restaurant is provided, and is not a Toast Now restaurant,
  // then we can show the POS access code.
  const showPasscodeField = !!restaurant && !restaurant?.isToastNowOnly
  const passcodeMinLength = /@toasttab.com$/.test(userAccount?.email) ? 6 : 3
  const { isMarriottPilotRestaurant, isEnterpriseCustomer } = useFlags()
  return (
    <BasePage
      showPasscodeDetails={showPasscodeField}
      passcodeMinLength={passcodeMinLength}
      showPassword={!userAccount?.hasPassword}
    >
      <ContentHeader
        title='Activate your Toast account'
        description={getSubtitleText({
          showPasscodeField,
          showPassword: !userAccount?.hasPassword
        })}
        contentDivider
      />
      {alert}
      {!isActivationSession && (
        <Alert className='mb-6' variant='success' showIcon>
          You have already successfully activated your account! You can leave
          this page.
        </Alert>
      )}
      <ActivationForm
        {...userAccount}
        isMarriottPilotRestaurant={isMarriottPilotRestaurant}
        isEnterpriseCustomer={isEnterpriseCustomer}
        location={restaurant?.name}
        showPasscodeField={showPasscodeField}
        application={application}
        onSubmit={activateAccount}
        onCancel={() => window.location.assign(getLoginUrl(application))}
        fieldsDisabled={!isActivationSession}
      />
    </BasePage>
  )
}

App.propTypes = {
  application: string,
  restaurant: shape({
    isToastNowOnly: bool
  }),
  userAccount: shape({
    email: string,
    passcode: string
  }).isRequired,
  activateAccount: func.isRequired,
  children: oneOfType([node, arrayOf(node)]),
  isActivationSession: bool.isRequired
}
