import {
  getCurrentEnvironment,
  ToastEnvironment
} from '@toasttab/current-environment'

export const Application = Object.freeze({
  PAYROLL: 'PAYROLL',
  TOAST: 'TOAST',
  XTRACHEF: 'XTRACHEF'
})

// The page is hosted on Toastweb, no need to provide the origin
export const getToastwebLoginUrl = () => '/login'

export const getLoginUrl = (application = Application.TOAST) => {
  if (application === Application.PAYROLL) {
    return getPayrollLoginUrl()
  }
  if (application === Application.XTRACHEF) {
    return getXtraChefLoginUrl()
  }
  return getToastwebLoginUrl()
}

const getPayrollLoginUrl = () => {
  switch (getCurrentEnvironment()) {
    case ToastEnvironment.DEV:
      return 'https://payroll.eng.toastteam.com/CompanyCode'
    case ToastEnvironment.PROD:
      return 'https://payroll.toasttab.com/CompanyCode'
    case ToastEnvironment.PREPROD:
    default:
      // Payroll doesn't have a sandbox env
      return 'https://payroll.preprod.eng.toasttab.com/CompanyCode'
  }
}

const getXtraChefLoginUrl = () => {
  switch (getCurrentEnvironment()) {
    case ToastEnvironment.DEV:
      return 'https://bpo.xtrachef.com/XtraChefAccount/Account/LogOn'
    case ToastEnvironment.PROD:
      return 'https://app.xtrachef.com/XtraChefAccount/Account/LogOn'
    case ToastEnvironment.PREPROD:
      return 'https://preprod.xtrachef.com/XtraChefAccount/Account/LogOn'
    default:
      return 'https://preprod.xtrachef.com/XtraChefAccount/Account/LogOn'
  }
}
