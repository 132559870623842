import React from 'react'
import { ContentFooter } from '@toasttab/buffet-pui-wizard-templates'
import { ButtonGroup } from '@toasttab/buffet-pui-buttons'
import { SubmitButton, CancelButton } from '@toasttab/buffet-pui-forms'
import { ArrowForwardIcon } from '@toasttab/buffet-pui-icons'
import { bool, func } from 'prop-types'

export const FooterActions = ({ onCancel, disabled, canSubmit }) => {
  return (
    <ContentFooter className='bg-white md:justify-end'>
      <ButtonGroup
        className='w-full p-4 md:w-auto md:space-x-2'
        testsId='next-continue-buttons'
      >
        <CancelButton
          disabled={disabled}
          variant='link'
          testId='cancel-button'
          className='w-1/2 justify-center md:w-auto'
          onClick={onCancel}
        >
          Cancel
        </CancelButton>
        <SubmitButton
          disabled={!canSubmit || disabled}
          className='w-1/2 md:w-auto'
          variant='primary'
          testId='continue-button'
          iconRight={<ArrowForwardIcon accessibility='decorative' />}
        >
          Activate
        </SubmitButton>
      </ButtonGroup>
    </ContentFooter>
  )
}

FooterActions.propTypes = {
  onCancel: func.isRequired,
  disabled: bool,
  canSubmit: bool
}
