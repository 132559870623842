import * as yup from 'yup'

export const ActivationValidationSchema = yup.object().shape({
  passcode: yup
    .string()
    .nullable(true)
    .optional()
    .test({
      name: 'isNumber',
      message: 'Only accepts numbers.',
      test: (passcode) => !passcode || /^\d+$/.test(passcode)
    })
    .max(8, 'Maximum length is 8 digits.')
    .when('email', (email, schema) => {
      const isCreatingToastUser = (email || '').includes('@toasttab.com')
      return schema.min(
        isCreatingToastUser ? 6 : 3,
        ({ min }) => `Minimum length is ${min} digits.`
      )
    })
})

export const EnterpriseIdSchema = yup.object().shape({
  enterpriseId: yup.string().required('Please enter your Enterprise ID')
})
