import React from 'react'
import { bool, shape, string, number } from 'prop-types'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { useFormattedError } from './useFormattedError'

export const MutationResponseAlert = ({ isSuccess, isError, error }) => {
  const formattedError = useFormattedError(error)

  if (isError) {
    return (
      <Alert className='mb-6' variant='error' showIcon>
        {formattedError}
      </Alert>
    )
  }

  if (isSuccess) {
    return (
      <Alert className='mb-6' variant='success' showIcon>
        You successfully activated your account! You'll now be redirected to the
        login page.
      </Alert>
    )
  }

  return null
}

MutationResponseAlert.propTypes = {
  isSuccess: bool.isRequired,
  isError: bool.isRequired,
  error: shape({
    response: shape({
      status: number,
      statusText: string,
      data: shape({
        fieldName: string,
        errorCause: string
      })
    }).isRequired
  })
}
