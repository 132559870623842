export function getSubtitleText({ showPasscodeField, showPassword }) {
  const posCodeAndPassword =
    'You will use this email and password to log in to your Toast account. Your POS access code will allow you to access the POS system at the location specified below.'
  const posCodeOnly =
    'You will continue to use this email and your existing password to log in to your Toast account. Your POS access code will allow you to access the POS system at the location specified below.'
  const passwordOnly =
    'You will use this email and password to log in to your Toast account.'

  if (showPasscodeField && showPassword) {
    return posCodeAndPassword
  } else if (showPasscodeField && !showPassword) {
    return posCodeOnly
  } else if (showPassword && !showPasscodeField) {
    return passwordOnly
  }
}
