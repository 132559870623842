import { useSentry } from 'banquet-runtime-modules'
// import { jsonStringifyWithoutCircularRefs } from '../../utils/jsonStringifyWithoutCircularRefs'

const FieldErrorsMap = {
  PASSWORD: {
    BLANK: 'Password is required for users with login capabilities.',
    LENGTH_SHORT: 'Password must be at least 7 characters.',
    NO_NUMBERS: 'Password must contain at least 1 number.',
    NO_LETTERS: 'Password must contain at least 1 letter.'
  },
  PASSCODE: {
    BLANK: 'POS access code required.',
    LENGTH_SHORT: 'Minimum length for POS access code is 3 digits.',
    TOAST_USER_TOO_SHORT: 'Minimum length for POS access code is 6 digits.',
    LENGTH_EXCEEDED: 'Maximum length for POS access code is 8 digits.',
    ILLEGAL_CHARACTERS: 'POS access code only accepts numbers.',
    CONFLICT_PASSCODE:
      'POS access code is conflicting with another one; Please write a different access code.'
  }
}
const CUSTOMER_IDENTITY_UNIQUE_VALUE_VIOLATED =
  'CUSTOMER_IDENTITY_UNIQUE_VALUE_VIOLATED'

const INVALID_FIELD = /INVALID_([^:]+)\s*:\s*(.+)/

const activationError = `Something went wrong and we can't activate your account right now. Please wait a minute or two and try again. If this problem continues, contact Toast Support.`
const eidExistError = `This Enterprise ID value has already been associated with another user.`

export const useFormattedError = (error) => {
  const { captureException } = useSentry()

  if (!error) {
    return null
  }

  if (!error?.response?.data) {
    // const { config, headers, request, ...rest } = error.response
    // const sanitizedError = jsonStringifyWithoutCircularRefs(rest)

    captureException(
      new Error(
        `An error occurred: { status: ${error?.response?.status}, statusText: ${error?.response?.statusText}}`
      )
    )
    return activationError
  }

  const { errorCause = '', developerMessage } = error?.response?.data

  if (developerMessage === CUSTOMER_IDENTITY_UNIQUE_VALUE_VIOLATED) {
    return eidExistError
  }

  const isValidationError = INVALID_FIELD.test(errorCause)
  if (!isValidationError) {
    return activationError
  }

  const [, field, validationError] = INVALID_FIELD.exec(errorCause)
  return FieldErrorsMap[field][validationError]
}
