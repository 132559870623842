import React from 'react'

const ExternalLink = ({ children, testId = 'link', ...props }) => (
  <a
    target='_blank'
    className='text-primary-75 no-underline'
    rel='noopener noreferrer'
    data-testid={testId}
    {...props}
  >
    {children}
  </a>
)

export const Footer = () => (
  <p className='text-center text-secondary type-caption md:text-left'>
    By activating your account, you agree to Toast's{' '}
    <ExternalLink
      href='https://pos.toasttab.com/end-user-license-agreement'
      testId='end-user-license-link'
    >
      End User License Agreement
    </ExternalLink>{' '}
    and acknowledge that your information will be processed pursuant to Toast's{' '}
    <ExternalLink href='https://pos.toasttab.com/privacy' testId='privacy-link'>
      Privacy Statement
    </ExternalLink>
    .
  </p>
)
