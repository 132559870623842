import React, { StrictMode } from 'react'
import { ReactQueryCacheProvider, QueryCache } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import { ConnectedApp } from './App'

const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

export const AppBootstrap = () => (
  <StrictMode>
    <ReactQueryCacheProvider queryCache={queryCache}>
      <BrowserRouter basename='/account/user-activation'>
        <div data-ua>
          <ConnectedApp />
        </div>
      </BrowserRouter>
    </ReactQueryCacheProvider>
  </StrictMode>
)
