import React from 'react'
import { ToastLogo } from '@toasttab/buffet-pui-toast-logo'

export const PageHeader = () => (
  <div
    data-testid='page-header'
    className='fixed top-0 z-50 flex w-full flex-col'
  >
    <header className='relative flex items-center justify-between type-default'>
      <div className='flex h-12 w-full items-center truncate shadow-md md:h-16 md:shadow-none'>
        <ToastLogo size='lg' />
        <div className='flex h-full w-full items-center bg-gray-0 md:border-b' />
      </div>
    </header>
  </div>
)
