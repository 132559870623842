import { useLocation } from 'react-router-dom'
import { useSentry } from 'banquet-runtime-modules'

/**
 * Build the response for useReturnUrl.
 * @param {URLSearchParams} queryParams
 * Valid query parameters to be injected to the login page redirection.
 * @param {string?} decodedReturnUrl
 * Decoded returnUrl from the query parameters.
 * @returns {UseReturnUrlResponse} The built response.
 */
const buildResponse = (queryParams, decodedReturnUrl = null) => ({
  redirectUrl: queryParams.toString(),
  decodedReturnUrl
})

/**
 * @typedef UseReturnUrlResponse Response for useReturnUrl hook.
 * @property {string} redirectUrl
 * The built URL to redirect user to the login page, with specific query
 * parameters.
 * @property {string?} decodedReturnUrl
 * The decoded returnUrl query parameter from the SPA. It may be null if the
 * query parameter is absent, or the path is invalid/malformed.
 */

/**
 * @deprecated Prefer using the one built in `spa-account-forgot-password`
 * (should be moved to buffet at some point).
 *
 * Build an URL redirecting to the login page, with specific query parameters:
 * - isActivatingAccount, always true
 * - message, always invitation.success
 * - email, based on given email (skipped if absent)
 * - returnUrl, based on returnUrl from the SPA query parameters
 * (skipped if absent)
 * @param {string} email
 * User email address used during the activation.
 * @returns {UseReturnUrlResponse}
 * The redirectUrl for the redirection, and the decodedReturnUrl from the
 * original returnUrl query parameter.
 */
export const useReturnUrl = (email) => {
  const { captureMessage } = useSentry()
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const returnUrl = params.get('returnUrl')
  const redirectionParams = new URLSearchParams({
    isActivatingAccount: true,
    message: 'invitation.success'
  })
  let decodedReturnUrl = null

  if (!!email) {
    redirectionParams.append('email', email)
  }

  if (!returnUrl) {
    return buildResponse(redirectionParams, decodedReturnUrl)
  }

  try {
    decodedReturnUrl = decodeURIComponent(returnUrl)

    try {
      const redirectURL = new URL(decodedReturnUrl)
      if (redirectURL.origin === window.location.origin) {
        redirectionParams.append('returnUrl', redirectURL.href)
      } else {
        decodedReturnUrl = null
        captureMessage(
          `[Invalid URL] returnUrl is absolute but origin is not Toastweb: ${decodedReturnUrl}`,
          'warning'
        )
      }
    } catch (urlWithoutBaseError) {
      /**
       * URL constructor requires an absolute path, it should fail here if
       * `decodedReturnUrl` is relative.
       */

      try {
        const redirectURL = new URL(decodedReturnUrl, window.location.origin)
        redirectionParams.append('returnUrl', redirectURL.href)
      } catch (urlWithBaseError) {
        decodedReturnUrl = null
        captureMessage(
          `[Invalid URL] returnUrl is relative but malformed: ${decodedReturnUrl}`,
          'warning'
        )
      }
    }
  } catch (decodingError) {
    captureMessage(
      `[Invalid URL] returnUrl is malformed: ${decodedReturnUrl}`,
      'warning'
    )
  }

  return buildResponse(redirectionParams, decodedReturnUrl)
}
