import React from 'react'
import {
  EmailDisplayIcon,
  PointOfSaleDisplayIcon
} from '@toasttab/buffet-pui-icons'
import { PageInfoList } from '@toasttab/buffet-pui-wizard-templates'

export const Details = ({ showPasscode = true, passcodeMinLength = 3 }) => {
  const items = [
    {
      Icon: EmailDisplayIcon,
      header: 'How do I change my Toast account email?',
      description:
        'You can change your Toast account email in the login information section within your My Account page. Note, you will need to reverify when changing to a new email.'
    }
  ]
  showPasscode &&
    items.push({
      Icon: PointOfSaleDisplayIcon,
      header: 'What is POS access code?',
      description: `A unique ${passcodeMinLength}-8 digit numerical code that you will enter to log into your POS terminal. Note, you will have multiple POS access codes if you work at multiple locations.`
    })

  return <PageInfoList items={items} />
}
