import axios from 'axios'

export const BASE_API_URL = '/api/restaurants/employees'

export const ReactQueryKeys = Object.freeze({
  ACTIVATION_CONTEXT: 'activationContext'
})

export async function getActivationContext() {
  const { data } = await axios.get(`${BASE_API_URL}/user-account`)
  return data
}

export async function activateAccount(userData) {
  const { data } = await axios.post(`${BASE_API_URL}/activate`, userData)
  return data
}
