import { useBanquetProps } from 'banquet-runtime-modules'

/**
 * Used to map flags passed in via banquet
 */
export const useFlags = () => {
  const { featureFlags } = useBanquetProps()
  if (!featureFlags) return {}

  const isEnterpriseCustomerFlags = [
    'mu-marriott-pilot-restaurant',
    'hot-caribou-sso-activation'
  ]

  return {
    isMarriottPilotRestaurant:
      featureFlags['mu-marriott-pilot-restaurant'] ?? false,
    isEnterpriseCustomer: isEnterpriseCustomerFlags.some(
      (flag) => featureFlags.hasOwnProperty(flag) && featureFlags[flag] === true
    )
  }
}
